import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { slugifyText } from "../../utils/utils"

export default function Box({ box, title, excerpt, category, cover }) {
    const slug = `/${ slugifyText(category) }/${ slugifyText(title) }`
    const excerptShortened = excerpt.replace(/^(.{200}[^\s]*).*/, "$1") + ' ...'
    const excerptShortened300 = excerpt.replace(/^(.{300}[^\s]*).*/, "$1") + ' ...'
    const excerptShortened120 = excerpt.replace(/^(.{120}[^\s]*).*/, "$1") + ' ...'
    const excerptShortened80 = excerpt.replace(/^(.{80}[^\s]*).*/, "$1") + ' ...'

    const box1 = 
        <div className="box box-featured box-controll">
            <div className="box-content">
                <div className="box-fill">
                    <a href={slug} className="display-block" title={title}>
                        <span className="row row-glairy display-block">
                            <span className="col-glairy col-xs-12 col-md-12 display-block">
                                <span className="box-image">
                                    { cover && <Img className="image-full-width" fluid={cover} alt={title} /> }
                                </span>
                            </span>               
                            <span className="col-glairy col-xs-12 col-md-12 display-block">
                                <span className="box-text">
                                    <h2>{ title }</h2>
                                    <p>{ excerpt }</p>
                                </span>
                            </span>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    
    const box2 = 
        <div className="box box-big-labeled">
            <div className="box-content">
                <div className="box-fill">
                    <a href={slug} title={title} className="display-block">
                    <span className="row row-glairy display-block">
                            <span className="col-glairy col-xs-12 col-md-12 display-block">
                                <span className="box-image">
                                    { cover && <Img className="image-full-width" fluid={cover} alt={title} /> }
                                </span>
                            </span>               
                            <span className="col-glairy col-xs-12 col-md-12 display-block">
                                <span className="box-text">
                                    <h2>{ title }</h2>
                                    <p>{ excerptShortened }</p>
                                </span>
                            </span>
                        </span>
                    </a>
                </div>
            </div>
        </div>

    const box3 = 
        <div className="box box-big-labeled">
            <div className="box-content">
                <div className="box-fill">
                    <a href={slug} title={title} className="display-block">
                    <span className="row row-glairy display-block">
                            <span className="col-glairy col-xs-12 col-md-12 display-block">
                                <span className="box-image">
                                    { cover && <Img className="image-full-width" fluid={cover} alt={title} /> }
                                </span>
                            </span>               
                            <span className="col-glairy col-xs-12 col-md-12 display-block">
                                <span className="box-text">
                                    <h2>{ title }</h2>
                                </span>
                            </span>
                        </span>
                    </a>
                </div>
            </div>
        </div>

    const box4 =
        <div className="box box-normal">
            <div className="box-content">
                <div className="box-fill">
                    <a href={slug} title={title} className="display-block">
                        <span className="row row-glairy display-block">
                            <span className="col-glairy col-xs-12 col-md-12 display-block">
                                <span className="box-text">
                                    <h2>{ title }</h2>
                                    <p>{ excerptShortened80 }</p>
                                </span>
                            </span>
                        </span>
                    </a>
                </div>
            </div>
        </div>

    const box5 =
        <div className="box box-featured-middle">
            <div className="box-content">
                <div className="box-fill">
                    <a href={slug} title={title} className="display-block">
                        <span className="row row-glairy display-block">
                            <span className="col-glairy col-xs-12 col-md-12 display-block">
                                <span className="box-text">
                                    <h2>{ title }</h2>
                                </span>
                            </span>
                        </span>
                    </a>
                </div>
            </div>
        </div>

    const box6 =
        <div className="box box-normal">
            <div className="box-content">
                <div className="box-fill">
                    <a href={slug} title={title} className="display-block">
                        <span className="row row-glairy display-block">
                            <span className="col-glairy col-xs-4 col-md-4 display-block">
                                <span className="box-image">
                                    { cover && <Img className="image-full-width" fluid={cover} alt={title} /> }
                                </span>
                            </span>               
                            <span className="col-glairy col-xs-8 col-md-8 display-block">
                                <span className="box-text">
                                    <h2>{ title }</h2>
                                    <p>{ excerptShortened80 }</p>
                                </span>
                            </span>
                        </span>
                    </a>
                </div>
            </div>
        </div>

    const box7 =
        <div className="box box-normal-3">
            <div className="box-content">
                <div className="box-fill">
                    <a href={slug} title={title} className="display-block">
                        <span className="row row-glairy display-block">
                            <h2>{ title }</h2>
                            <span className="col-glairy col-xs-4 col-md-4 display-block">
                                <span className="box-image">
                                    { cover && <Img className="image-full-width" fluid={cover} alt={title} /> }
                                </span>
                            </span> 
                            <span className="col-glairy col-xs-8 col-md-8 display-block">
                                <span className="box-text">
                                    <p>{ excerptShortened }</p>
                                </span>
                            </span>
                        </span>
                    </a>
                </div>
            </div>
        </div>

    const box8 =
        <div className="box box-big-labeled">
            <div className="box-content">
                <div className="box-fill">
                    <a href={slug} title={title} className="display-block">
                        <span className="row row-glairy display-block">
                            <span className="col-glairy col-xs-12 col-md-12 display-block">
                                <span className="box-image">
                                    { cover && <Img className="image-full-width" fluid={cover} alt={title} /> }
                                </span>
                            </span>               
                            <span className="col-glairy col-xs-12 col-md-12 display-block">
                                <span className="box-text">
                                    <h2>{ title }</h2>
                                    <p>{ excerptShortened }</p>
                                </span>
                            </span>
                        </span>
                    </a>
                </div>
            </div>
        </div>

    const box9 =
        <div className="box box-normal">
            <div className="box-content">
                <div className="box-fill">
                    <a href={slug} title={title} className="display-block">
                        <span className="row row-glairy display-block">
                            <span className="col-glairy col-xs-12 col-md-12 display-block">
                                <span className="box-text">
                                    <h2>{ title }</h2>
                                </span>
                            </span>
                        </span>
                    </a>
                </div>
            </div>
        </div>

    const box10 =
        <div className="box box-normal-4">
            <div className="box-content">
                <div className="box-fill">
                    <a href={slug} title={title} className="display-block">
                        <span className="row row-glairy display-block">
                            <span className="col-glairy col-xs-4 col-md-4 display-block">
                                <span className="box-image">
                                    { cover && <Img className="image-full-width" fluid={cover} alt={title} /> }
                                </span>
                            </span> 
                            <span className="col-glairy col-xs-8 col-md-8 display-block">
                                <span className="box-text">
                                    <h2>{ title }</h2>
                                    <p>{ excerptShortened }</p>
                                </span>
                            </span>
                        </span>
                    </a>
                </div>
            </div>
        </div>

    const box11 =
        <div className="box box-featured-subtitled box-extra-int box-extra-int-user">
            <div className="box-content">
                <div className="box-fill">
                    <a href={slug} title={title} className="display-block">
                        <span className="row row-glairy display-block">
                            <span className="col-glairy col-xs-12 col-md-12 display-block">
                                <span className="box-image">
                                    { cover && <Img className="image-full-width" fluid={cover} alt={title} /> }
                                </span>
                            </span>               
                            <span className="col-glairy col-xs-12 col-md-12 display-block">
                                <span className="box-text">
                                    <h2>{ title }</h2>
                                    <p>{ excerptShortened }</p>
                                </span>
                            </span>
                        </span>
                    </a>
                </div>
            </div>
        </div>

    const box12 =
        <div className="box box-featured-subtitled box-extra-int box-extra-int-user">
            <div className="box-content">
                <div className="box-fill">
                    <a href={slug} title={title} className="display-block">
                        <span className="row row-glairy display-block">
                            <span className="col-glairy col-xs-12 col-md-12 display-block">
                                <span className="box-image">
                                    { cover && <Img className="image-full-width" fluid={cover} alt={title} /> }
                                </span>
                            </span>               
                            <span className="col-glairy col-xs-12 col-md-12 display-block">
                                <span className="box-text">
                                    <h2>{ title }</h2>
                                </span>
                            </span>
                        </span>
                    </a>
                </div>
            </div>
        </div>

    const box13 = 
        <div className="box box-featured-invert">
            <div className="box-fill">
                <a href={slug} title={title} className="display-block">
                    <span className="row row-glairy display-block">
                        <span className="col-glairy col-xs-12 col-md-12 display-block">
                            <span className="box-image">
                                { cover && <Img className="image-full-width" fluid={cover} alt={title} /> }
                            </span>
                        </span>               
                        <span className="col-glairy col-xs-12 col-md-12 display-block">
                            <span className="box-text">
                                <h2>{ title }</h2>
                            </span>
                        </span>
                    </span>
                </a>
            </div>
        </div>

    const box14 =
        <div className="authors_contents_int">
            <div className="box-hu">
                <a href={slug} title={title} className="display-block">
                    <span className="row row-glairy display-block">
                        <span className="col-glairy col-xs-12 col-md-4 display-block">
                            <span className="box-image">
                                { cover && <Img className="image-full-width" fluid={cover} alt={title} /> }
                            </span>
                        </span>               
                        <span className="col-glairy col-xs-12 col-md-8 display-block">
                            <span className="box-text">
                                <h2>{ title }</h2>
                                <p>{ excerptShortened300 }</p>
                            </span>
                        </span>
                    </span>
                </a>
            </div>
        </div>

    const box15 =
        <div className="box box-featured-small">
            <div className="box-content">
                <div className="box-fill">
                    <a href={slug} title={title} className="display-block">
                        <span className="row row-glairy display-block">
                            <div className="col-glairy col-xs-12 col-md-12 display-block" style={{ paddingBottom: `7px` }}>
                                <span className="box-image">
                                    { cover && <Img className="image-full-width" fluid={cover} alt={title} /> }
                                </span>
                            </div>
                            <div className="col-glairy col-xs-12 col-md-12 display-block" style={{ paddingBottom: `7px` }}>
                                <span className="box-text">
                                    <h2>{ title }</h2>
                                </span>
                            </div>
                        </span>
                    </a>
                </div>
            </div>
        </div>

    const box16 = 
        <div className="box-fill" style={{ width: `33%`, float: `left` }}>
            <a href={slug} title={title} className="display-block">
                <span className="row row-glairy display-block">
                    <span className="col-glairy col-xs-12 col-md-12 display-block">
                        <span className="box-image">
                            { cover && <Img className="image-full-width" fluid={cover} alt={title} /> }
                        </span>
                    </span>
                    <span className="col-glairy col-xs-12 col-md-12 display-block">
                        <span className="box-text">
                            <h2>{ title }</h2>
                            <p>{ excerptShortened120 }</p>
                        </span>
                    </span>
                </span>
            </a>
        </div>

    const box17 =
        <div className="box-fill" style={{ width: `20%`, float: `left` }}>
            <a href={slug} title={title} className="display-block">
                <span className="row row-glairy display-block">
                    <span className="col-glairy col-xs-12 col-md-12 display-block">
                        <span className="box-image">
                            { cover && <Img className="image-full-width" fluid={cover} alt={title} /> }
                        </span>
                    </span>
                    <span className="col-glairy col-xs-12 col-md-12 display-block">
                        <span className="box-text">
                            <h2>{ title }</h2>
                            <p>{ excerptShortened }</p>
                        </span>
                    </span>
                </span>
            </a>
        </div>

    const box18 = 
        <div className="authors_contents_int">
            <div className="box-hu">
                <a href={slug} title={title} className="display-block">                
                    <span className="row row-glairy display-block">
                        <span className="col-glairy col-xs-12 col-md-4 display-block">
                            <span className="box-image">
                                { cover && <Img className="image-full-width" fluid={cover} alt={title} /> }
                            </span>
                        </span>
                        <span className="col-glairy col-xs-12 col-md-8 display-block">
                            <span className="box-text">
                                <div style={{ color: `#252525` }}><b>{ title }</b></div>
                                <p>{ excerptShortened }</p>
                            </span>
                        </span>
                    </span>
                </a>
            </div>
        </div>

    const box19 =
        <div className="box box-normal">
            <div className="box-content">
                <div className="box-fill">
                    <a href={slug} title={title} className="display-block">
                        <span className="row row-glairy display-block">
                            <span className="col-glairy col-xs-4 col-md-4 display-block">
                                <span className="image-full-width">
                                    <img src={cover} alt={title}></img>
                                </span>
                            </span>               
                            <span className="col-glairy col-xs-8 col-md-8 display-block">
                                <span className="box-text">
                                    <h2>{ title }</h2>
                                    <p>{ excerptShortened80 }</p>
                                </span>
                            </span>
                        </span>
                    </a>
                </div>
            </div>
        </div>

    const boxes = {
         1: box1,
         2: box2,
         3: box3,
         4: box4,
         5: box5,
         6: box6,
         7: box7,
         8: box8,
         9: box9,
        10: box10,
        11: box11,
        12: box12,
        13: box13,
        14: box14,
        15: box15,
        16: box16,
        17: box17,
        18: box18,
        19: box19
    }

    return boxes[box]
}